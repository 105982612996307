<template>
  <div class="home">
    <h1>Welcome to the Player!</h1>
    <label>Select your routine: </label>
    <select v-model="selectedRoutineName">
      <option v-for="routine in Object.keys(allRoutines)" :key="routine" :value="routine">{{routine}}</option>
    </select>

    <button @click="start">START</button>

    <div id="player" :class="{'active':isPlaying}" @click="togglePausePlay">
      <div class="title">{{currentStep.name}}</div>
      <div v-if="!nextStep" class="anim" :style="{'background': 'url(/'+currentStep.thumbnail+') no-repeat center center'}"></div>
      <template v-else>
        <div class="coming">Coming: {{nextStep.name}}</div>
        <div class="coming-image" :style="{'background': 'url(/'+nextStep.thumbnail+') no-repeat center center'}"></div>
      </template>
      <div class="counter" :class="{resting:isResting}">{{counter}}</div>
    </div>
  </div>
</template>

<script>
const second = 1000

const restStep = {
  name: 'Rest',
  thumbnail: 'images/rest/rest.png'
}
export default {
  name: 'Player',
  data () {
    return {
      selectedRoutineName: '',
      currentStep: false,
      counter: 0,
      isPlaying: false,
      isResting: false,
      isPaused: false
    }
  },
  computed: {
    allRoutines () {
      return JSON.parse(localStorage.getItem('routines')) || {}
    },
    selectedRoutine () {
      return this.allRoutines[this.selectedRoutineName]
    }
  },
  methods: {
    start () {
      console.log('ROUTINE STARTED', this.selectedRoutine)
      this.isPlaying = true
      this.playStep(0)
    },
    playStep (i) {
      this.currentStep = this.selectedRoutine.moves[i]
      console.log('PLAY STEP', this.currentStep)
      this.counter = this.currentStep.time
      const interval = setInterval(() => {
        if (this.isPaused) {
          return
        }
        this.counter--
        if (this.counter === 0) {
          clearInterval(interval)
          i++
          if (i < this.selectedRoutine.moves.length) {
            console.log('NEXT STEP')
            this.nextStep = this.selectedRoutine.moves[i]
            this.isResting = true
            this.counter = this.selectedRoutine.settings.restingTime
            this.currentStep = restStep
            const restinterval = setInterval(() => {
              if (this.isPaused) {
                return
              }
              this.counter--
              if (this.counter === 0) {
                clearInterval(restinterval)
                this.isResting = false
                this.nextStep = false
                this.playStep(i)
              }
            }, second)
          } else {
            console.log('END')
            this.isPlaying = false
          }
        }
      }, second)
    },
    togglePausePlay () {
      console.log('togglePausePlay')
      this.isPaused = !this.isPaused
    }
  },
  watch: {
    isPlaying (isPlaying) {
      console.log(isPlaying)
      var elem = document.getElementById('player')
      if (isPlaying) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen()
        } else if (elem.webkitRequestFullscreen) { /* Safari */
          elem.webkitRequestFullscreen()
        } else if (elem.msRequestFullscreen) { /* IE11 */
          elem.msRequestFullscreen()
        }
      } else {
        if (elem.exitFullscreen) {
          elem.exitFullscreen()
        } else if (elem.webkitExitFullscreen) { /* Safari */
          elem.webkitExitFullscreen()
        } else if (elem.msExitFullscreen) { /* IE11 */
          elem.msExitFullscreen()
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>

#player:not(.active){
  display:none;
}

#player.active{
  position:fixed;
  top: 0;
  left: 0;
  width:100%;
  height:100%;
  background-color:lightgray;
  z-index:998;

  .title{
    position:absolute;
    font-size: 5em;
    width:100%;
    text-align: center;
    z-index:999;
    top: 0;
  }

  .anim{
    position:absolute;
    width:100%;
    height:100%;
    text-align: center;
  }

  .coming{
    position:absolute;
    width: 100%;
    font-size: 3em;
    top: 4em;
    text-align: center;
  }
  .coming-image{
    width: inherit;
    height: inherit;
    top: 13em;
    align-content: center;
    background-size: contain;
    background: no-repeat;
    position: relative;
    margin: auto;
  }

  .counter{
    position:fixed;
    font-size: 8em;
    bottom:0;
    right:10px;
    font-weight: bold;

    &.resting{
      color:green;
    }
  }

}

</style>
